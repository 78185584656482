.BoxChico{
    width: 8rem;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    background-color: "#f5f5f5";
    padding-right: 2.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.BoxChico img{
    height: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.BoxChico p{
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 800;
    color: #000000;
    margin-right: 2rem;

}

.BoxChico:hover{
    transform: scale(0.98);
    border-radius: 10px;
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.3);
}