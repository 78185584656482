/* === removing default button style ===*/

.contenedorHabilidades{
    margin: 0.8rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, 
                            minmax(min(8.25rem, 100%), 1fr));
    grid-gap: 0.1rem;
    justify-items: center; /* Centra los elementos horizontalmente */

}



.titulosh3{
    font-size: 1.3rem;
    font-weight: 600;
    color: #000000;
    margin-left: 1rem;
}
.aligncenter{
  text-align: center;
  font-size: 2rem;
}


.buttonMovil {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    margin-left: 1rem;
  }
  

  /* button styling */
  .buttonMovil {
    --text-stroke-color: rgba(0, 0, 0, 0.6);
    --animation-color: #37FF8B;
    --fs-size: 2em;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: var(--fs-size);
    font-family: "Arial";
    position: relative;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px var(--text-stroke-color);
  }
  
  /* this is the text, when you hover on button */
  .hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
  }
  /* hover */
  .buttonMovil:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color))
  }

.card1 {
    width: 7rem;
    height: 10rem;
    border-radius: 20px;
    transition: all .3s;
    border: 2px solid #757575;
   }

   .card1 img{
    margin-top:0.5rem;
    width: 6rem;
    height: 6rem;
    margin-left: 0.35rem;
    margin-bottom: 0.8rem;
    border-radius: 20px;
    transition: all .2s;
   }
 

.card2 {
  width: 6.7rem;
  height: 9.7rem;
    background-color: #ffffff;
    border-radius: 20px;
    transition: all .2s;
   }
.card2:hover {
    transform: scale(0.88);
    border-radius: 30px;
}
   
   .card1:hover {
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.3);
   }



   .skill-box{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: left ;
    border-radius: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    }
  
  .skill-box .title1 {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: rgb(67, 67, 67);
  }
  
  .skill-box .skill-bar {
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 3px;
    background: rgba(236, 236, 236, 0.1);
  }
  
  .skill-bar .skill-per {
    position: relative;
    display: block;
    height: 100%;
    width: 90%;
    border-radius: 6px;
    background: rgb(226, 226, 226);
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
  }

  
  
  @keyframes progress {
    0% {
      width: 0;
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .skill-per .tooltip1 {
    position: absolute;
    right: -14px;
    top: -32px;
    font-size: 9px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 3px;
    background: rgba(226, 226, 226, 0.507);
    z-index: 1;
  }
  
  .tooltip1::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: rgb(226, 226, 226);
    transform: translateX(-50%) rotate(45deg);
  }
  