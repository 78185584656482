.contenedorHabilidades {
  grid-gap: .1rem;
  grid-template-columns: repeat(auto-fit, minmax(min(8.25rem, 100%), 1fr));
  justify-items: center;
  margin: .8rem;
  display: grid;
}

.titulosh3 {
  color: #000;
  margin-left: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.aligncenter {
  text-align: center;
  font-size: 2rem;
}

.buttonMovil {
  cursor: pointer;
  --text-stroke-color: #0009;
  --animation-color: #37ff8b;
  --fs-size: 2em;
  letter-spacing: 3px;
  font-size: var(--fs-size);
  text-transform: uppercase;
  color: #0000;
  -webkit-text-stroke: 1px var(--text-stroke-color);
  background: none;
  border: none;
  height: auto;
  margin: 0 0 0 1rem;
  padding: 0;
  font-family: Arial;
  text-decoration: none;
  position: relative;
}

.hover-text {
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  border-right: var(--border-right) solid var(--animation-color);
  -webkit-text-stroke: 1px var(--animation-color);
  width: 0%;
  transition: all .5s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.buttonMovil:hover .hover-text {
  filter: drop-shadow(0 0 23px var(--animation-color));
  width: 100%;
}

.card1 {
  border: 2px solid #757575;
  border-radius: 20px;
  width: 7rem;
  height: 10rem;
  transition: all .3s;
}

.card1 img {
  border-radius: 20px;
  width: 6rem;
  height: 6rem;
  margin-top: .5rem;
  margin-bottom: .8rem;
  margin-left: .35rem;
  transition: all .2s;
}

.card2 {
  background-color: #fff;
  border-radius: 20px;
  width: 6.7rem;
  height: 9.7rem;
  transition: all .2s;
}

.card2:hover {
  border-radius: 30px;
  transform: scale(.88);
}

.card1:hover {
  box-shadow: 0 0 30px 2px #0000004d;
}

.skill-box {
  align-items: left;
  border-radius: 10px;
  flex-direction: column;
  margin: 0;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

.skill-box .title1 {
  color: #434343;
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.skill-box .skill-bar {
  background: #ececec1a;
  border-radius: 6px;
  width: 100%;
  height: 8px;
  margin-top: 3px;
}

.skill-bar .skill-per {
  opacity: 0;
  background: #e2e2e2;
  border-radius: 6px;
  width: 90%;
  height: 100%;
  animation: .4s ease-in-out forwards progress;
  display: block;
  position: relative;
}

@keyframes progress {
  0% {
    opacity: 1;
    width: 0;
  }

  100% {
    opacity: 1;
  }
}

.skill-per .tooltip1 {
  color: #000;
  z-index: 1;
  background: #e2e2e281;
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 9px;
  font-weight: bold;
  position: absolute;
  top: -32px;
  right: -14px;
}

.tooltip1:before {
  content: "";
  z-index: -1;
  background-color: #e2e2e2;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
}

.BoxChico {
  background-color: "#f5f5f5";
  border-radius: 10px;
  flex-direction: row;
  width: 8rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-right: 2.5rem;
  display: flex;
}

.BoxChico img {
  height: 1.5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.BoxChico p {
  color: #000;
  margin-top: .5rem;
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: 800;
}

.BoxChico:hover {
  border-radius: 10px;
  transform: scale(.98);
  box-shadow: 0 0 30px 2px #0000004d;
}

.orange {
  color: #ff7a01;
}

.form-container {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  background-color: #ededed90;
  border-left: 5px solid #000;
  margin-bottom: .2rem;
  margin-left: 1rem;
  margin-right: .5rem;
  padding: 2rem;
}

.heading {
  color: #000;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 800;
  display: block;
}

.form-container .form .input {
  color: #000;
  background-color: #fff;
  border: none;
  border-left: 1px solid #0000;
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: bold;
  transition: all .2s ease-in-out;
}

.form-container .form .input:focus {
  border-left: 5px solid #000;
}

.form-container .form .textarea {
  color: #010101;
  resize: none;
  background-color: #fff;
  border: none;
  border-left: 1px solid #0000;
  outline: none;
  width: 100%;
  max-height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: bold;
  transition: all .2s ease-in-out;
}

.form-container .form .textarea:focus {
  border-left: 5px solid #000;
}

.form-container .form .button-container {
  gap: 10px;
  display: flex;
}

.form-container .form .button-container .send-button {
  color: #001925;
  text-align: center;
  background: #4e567e;
  border: 3px solid #0000;
  flex-basis: 100%;
  padding: 10px;
  font-weight: bold;
  transition: all .2s ease-in-out;
}

.form-container .form .button-container .send-button:hover {
  color: #000;
  background: none;
  border: 3px solid #000;
}
/*# sourceMappingURL=index.65d2b807.css.map */
