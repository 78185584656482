.orange {
    color: #ff7a01;
  }
  
  .form-container {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
    margin-left: 1rem;
    background-color: #ededed90;
    padding: 2rem;
    border-left: 5px solid #000000;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  }
  
  .heading {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  
  .form-container .form .input {
    color: #000000;
    width: 100%;
    background-color: #ffffff;
    border: none;
    outline: none;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border-left: 1px solid transparent;
  }
  
  .form-container .form .input:focus {
    border-left: 5px solid #000000;
  }
  
  .form-container .form .textarea {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff;
    color: #010101;
    font-weight: bold;
    resize: none;
    max-height: 150px;
    margin-bottom: 20px;
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .textarea:focus {
    border-left: 5px solid #000000;
  }
  
  .form-container .form .button-container {
    display: flex;
    gap: 10px;
  }
  
  .form-container .form .button-container .send-button {
    flex-basis: 100%;
    background: #4e567e;
    padding: 10px;
    color: #001925;
    text-align: center;
    font-weight: bold;
    border: 3px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .send-button:hover {
    background: transparent;
    border: 3px solid #000000;
    color: #000000;
  }
  
